'use client'

// React Imports
import {useEffect} from 'react'

// Hook Imports
import {useMedia} from 'react-use'

// Type Imports
import {useColorScheme} from '@mui/material'

import {useSettings} from '@core/hooks/useSettings'

const useLayoutInit = (colorSchemeFallback: 'light' | 'dark') => {
    // Hooks
    const {settings} = useSettings()
    const {setMode} = useColorScheme()
    const isDark = useMedia('(prefers-color-scheme: dark)', colorSchemeFallback === 'dark')

    useEffect(() => {
        const appMode = isDark ? 'dark' : 'light'

        // Imposta il cookie con SameSite=None e Secure
        document.cookie = `colorPref=${appMode}; SameSite=None; Secure`;

        if (settings.mode === 'system') {
            // Cambia il mode nel contesto delle impostazioni per applicare il cambiamento ai componenti MUI
            setMode(appMode)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDark])

    // Questo hook non restituisce nulla poiché è utilizzato solo per inizializzare il cookie delle preferenze di colore e il contesto delle impostazioni al primo caricamento
}

export default useLayoutInit
